/* General container styles */
.bot-settings {
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* Heading styles */
.bot-settings h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
}

/* Button styles */
.bot-settings button {
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.bot-settings button:hover {
    background-color: #0056b3;
}

.bot-settings button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Success and error messages */
.bot-settings .success-message {
    color: green;
    font-size: 16px;
}

.bot-settings .error-message {
    color: red;
    font-size: 16px;
}
