body {
    background: #0e101c;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.space-add-form-button {
    padding: 10px 15px;
    font-weight: 700;
    border: 1px solid #8c8c8c;
    font-size: 13px;
    border-top-left-radius: 20px;
    cursor: pointer;
    background: white;
    color: black;
}
.space-form-add-button-block {
    position: fixed;
    bottom: 50px;
    right: 10px;
    z-index: 10000;
}

.space-form h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}

.space-form-container{
}
.space-form-container .space-form{
    width: 20%;
    height: 20%;
    opacity: 0;
    position: fixed;
    z-index: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    top: 100%;
    transition: 0.5s linear all;
    margin: auto;
    overflow: hidden;
}
.space-form-title {
    font-weight: 700;
    font-size: 22px;
    background: #373737;
    color: white;
    padding: 15px;
    text-align: center;
}
.space-form-container.add-form .space-form{
    width: 500px;
    height: auto;
    opacity: 1;
    transition: 0.5s linear all;
    overflow-y: auto;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 75%;
}

.space-form p {
    color: #bf1650;
}
.space-form-block {
    position: relative;
    float: left;
    width: 100%;
    background: white;
    border: 1px solid black;
    padding: 15px;
}
.space-form p::before {
    display: inline;
    content: "⚠ ";
}
.space-form input {
    color: black;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #c4c4c4;
    padding: 5px 8px;
    margin-bottom: 10px;
    font-size: 15px;
}

.space-form label {
    line-height: 1;
    text-align: left;
    display: block;
    margin-bottom: 7px;
    margin-top: 20px;
    color: black;
    font-size: 17px;
}

.space-form input[type="submit"] {
    font-weight: 700;
    font-size: 18px;
    background: #e15858;
    color: white;
    padding: 7px;
    text-align: center;
    margin: 5px auto;
    position: relative;
    float: left;
}
.space-form-close-button {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff8f8;
    color: black;
    font-weight: 700;
    font-size: 20px;
    padding: 5px 15px;
    border: 1px solid #c6c6c6;
    cursor: pointer;
}
.space-form-close-button:hover {
    background: black;
    color: white;
}
.space-form input[type="submit"]:hover {
    background: #bf1650;
}

.space-form input[type="button"]:active,
.space-form input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}

.space-form input:disabled {
    opacity: 0.4;
}

.space-form input[type="button"]:hover {
    transition: 0.3s all;
}

.space-form input[type="button"],
.space-form input[type="submit"] {
    -webkit-appearance: none;
}

.App {
    max-width: 600px;
    margin: 0 auto;
}
.space-form-block-recaptcha {
    opacity: 0.7;
}
.grecaptcha-badge {
    bottom: 100px !important;
}

.user-error-message-block{
    width: 100%;
    text-align: center;
    margin-top: 100px;
}
footer {
    z-index: 100;
    position: fixed;
}
footer .py-1.footer {
    border: 1px solid #d7d7d7;
}

.space-filtering-block {
    position: fixed;
    z-index: 100;
    left: 10px;
    width: auto;
    height: auto;
    max-width: 20%;
}
.space-filtering-categories {
    background: #ffffffde;
    box-shadow: 5px 5px 5px #959595bd;
}
.default-category-filtering {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
}
.space-filtering-categories select {
    font-size: 13px;
}
.filtersActive .default-category-filtering {
    display: block;
}
.filtersActive .default-category-filter-block{
    padding: 10px;
    background: white;
}
.default-category-filtering {
    display: none;
}
.space-filtering-block .filters-open-icon {
    background: black;
    display: block;
    float: right;
    cursor: pointer;
    margin-left: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid gray;
    color: white;
    font-weight: 700;
    padding: 5px;
    font-size: 13px;
}
.submit-error-validation{
    color:red;
}
.space-form .error-message {
    color: red;
    font-weight: 600;
}
.space-form .success-message {
    color: green;
    font-weight: 600;
}
.space-form-container .space-form-add-background-block{
    width: 0;
    height: 0;
    position: fixed;
    background: rgb(128, 128, 128);
    opacity: 0.1;
    bottom: 0;
    right: 0;
    z-index: 0;
}
.space-form-container.add-form .space-form-add-background-block{
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: 0.5s linear opacity;
    opacity: 0.8;
}