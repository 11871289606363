.pac-container{
    z-index: 1111;
}

.map-autocomplete-input {
    position: relative;
    float: left;
    width: 100%;
    height: 35px;
    margin-bottom: 10px;
}
.location-picker-map {
    position: relative;
    float: left;
    width: 100%;
}
.location-linker-box .mb-3 {
     margin-bottom: 0 !important;
 }
.location-linker-autocomplete {
    background: #e8e8e8;
    padding: 15px;
    border-top: 2px solid black;
}
.location-linker-autocomplete .form-control {
    border-inline: 3px solid #aaa;
    line-height: 1;
    padding: 5px 10px !important;
    background: #f9f9f9;
}
.location-linker-box {
    margin-bottom: 20px;
}
