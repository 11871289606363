body {
    background: #0e101c;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.open-call-add-form-button {
    padding: 10px 20px;
    font-size: 40px;
    border: 1px solid #cacaca;
    cursor: pointer;
    background: #fff3e1;
    font-weight: 700;
    line-height: 40px;
    color: #757575;
    transition: 0.5s linear all;
}
.open-call-form-container {
    float: left;
    width: 100%;
    clear: both;
    margin: 50px 0;
}
.open-call-form-add-button-block {
    text-align: center;
}
.open-call-add-form-button:hover {
    border-radius: 25px !important;
    transition: 0.5s linear all;
    font-size: 50px;
    color: black;
}
.open-call-form h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}

.open-call-form-container{
}
.open-call-form-container .open-call-form{
    width: 20%;
    height: 20%;
    opacity: 0;
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    transition: 0.2s linear all;
    margin: auto;
    overflow: hidden;
}
.open-call-form-title {
    font-size: 18px;
    color: black;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #a8a8a8;
}
.open-call-form-container.add-form .open-call-form{
    height: auto;
    opacity: 1;
    transition: 0.2s linear all;
    overflow-y: auto;
    z-index: 1000;
    top: 100px;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 75%;
    max-width: 100vw;
    width: 500px;
}

.open-call-form p {
    color: #bf1650;
}
.open-call-form-block {
    position: relative;
    float: left;
    background: white;
    border: 1px solid black;
    padding: 15px;
    width: 100%;
}
.open-call-form p::before {
    display: inline;
    content: "⚠ ";
}

.open-call-form label {
    line-height: 1;
    text-align: left;
    display: block;
    margin-bottom: 7px;
    margin-top: 20px;
    color: black;
    font-size: 17px;
}

.open-call-form-close-button {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #262626;
    font-weight: 700;
    font-size: 20px;
    padding: 5px 15px;
    cursor: pointer;
}
.open-call-form-close-button:hover {
    background: black;
    color: white;
}
.open-call-submit-form-buttons {
    display: block;
    float: left;
    width: 100%;
    margin: 10px 0;
}
.cancel-button-open-call-form{
    font-weight: 700;
    font-size: 18px;
    background: #acacac;
    color: white;
    padding: 7px;
    text-align: center;
    margin: 5px auto;
    position: relative;
    float: left;
    display: block;
    box-sizing: border-box;
    width: 50%;
    border: 1px solid #c4c4c4;
    cursor: pointer;
}
.cancel-button-open-call-form:hover {
    background: gray;
}
.open-call-submit-button{
    font-weight: 700;
    font-size: 18px;
    background: #86bf7d;
    color: white;
    padding: 7px;
    text-align: center;
    margin: 5px auto;
    position: relative;
    float: left;
    display: block;
    box-sizing: border-box;
    width: 50%;
    border: 1px solid #c4c4c4;
}
.open-call-submit-button:hover {
    background: #62955b;
}
.open-call-submit-button:active,
.open-call-submit-button:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}
.open-call-submit-button:disabled {
    opacity: 0.4;
}
.open-call-submit-button:hover {
    transition: 0.3s all;
}
.open-call-submit-button,
.open-call-submit-button {
    -webkit-appearance: none;
}

.App {
    max-width: 600px;
    margin: 0 auto;
}
.open-call-form-block-recaptcha {
    opacity: 0.7;
}
.grecaptcha-badge {
    bottom: 100px !important;
}

.user-error-message-block{
    width: 100%;
    text-align: center;
    margin-top: 100px;
}
footer {
    z-index: 100;
    position: fixed;
}
footer .py-1.footer {
    border: 1px solid #d7d7d7;
}

.open-call-filtering-block {
    position: fixed;
    z-index: 100;
    left: 10px;
    width: auto;
    height: auto;
    max-width: 20%;
}
.open-call-filtering-categories {
    background: #ffffffde;
    box-shadow: 5px 5px 5px #959595bd;
}
.default-category-filtering {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
}
.open-call-filtering-categories select {
    font-size: 13px;
}
.filtersActive .default-category-filtering {
    display: block;
}
.filtersActive .default-category-filter-block{
    padding: 10px;
    background: white;
}
.default-category-filtering {
    display: none;
}
.open-call-filtering-block .filters-open-icon {
    background: black;
    display: block;
    float: right;
    cursor: pointer;
    margin-left: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid gray;
    color: white;
    font-weight: 700;
    padding: 5px;
    font-size: 13px;
}
.submit-error-validation{
    color:red;
}
.open-call-form .error-message {
    color: red;
    font-weight: 600;
}
.open-call-form .success-message {
    color: green;
    font-weight: 600;
}

.open-call-form-container .open-call-form-add-background-block{
    width: 0;
    height: 0;
    position: fixed;
    background: rgb(128, 128, 128);
    opacity: 0.1;
    bottom: 0;
    right: 0;
    z-index: 0;
}
.open-call-form-container.add-form .open-call-form-add-background-block{
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: 0.5s linear opacity;
    opacity: 0.8;
}