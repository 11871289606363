.brand-logo-about {
    margin: 25px 0px 25px;
}
.content-area-about h3 {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 100;
    color:#000000;
}
.copy-right {
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    padding: 15px 0;
    font-size: 12px;
    margin-top: 50px;
}
.content-area-about .content-area-flexible {
    text-align:center;
}

.linking-container{
    margin: 0 0 40px;
}
.linking-container.entry-point a {
    border: 1px solid #626262;
    padding: 2px 10px;
    text-decoration: none;
    color: black;
    width: 200px;
    display: inline-block;
    vertical-align: middle;
    background: #f9f2e0;
    margin: 5px 5px;
}
.linking-container.entry-point a:hover {
    border: 0px solid #4d4d4d;
    outline: 1px solid #6a6846;
    text-shadow: 1px 1px 1px #a2a082;
    box-shadow: 2px 2px 0 gray;
}
.linking-container.join-button a {
    border: 1px solid black;
    padding: 4px 14px;
    text-decoration: none;
    color: #464646;
    margin: 0px 5px;
    font-weight: 700;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    transition: 0.5s all linear;
    background: #f9f2e0;
}
.linking-container.join-button a:hover {
    color: black;
    transition: 0.5s all linear;
    border-radius: 10px 0 10px 0 !important;
    border: 1px solid black;
    outline: 1px solid #ffef00;
}
.social-block {
    margin: 25px 0;
}
.social-link img {
    width: 35px;
    margin: 0 5px;
}
.script-block.fade-in.three {
    width: 100%;
    margin: 25px 0;
}
.script-block-text {
    max-width: 520px;
    text-align: justify;
    text-justify: inter-word;
    margin: auto;
    padding: 0 10px;
}
.svg-artnet-timeline {

}
.timeline-block {
    margin: 50px 0 0;
}
.timeline-block-title {
    font-size: 15px;
    padding: 50px 0 15px;
}

@media (max-width: 768px) {

}
@media (max-width: 576px) {
    .brand-logo-about {
        margin: 0 0 20px;
        max-width: 230px;
    }
}
