.events-list-section {
    position:relative;
    float:left;
    width: 70%;
    padding-right: 25px;
    min-height: 100px;
}
.events-filters-section {
    position:relative;
    float:left;
    width: 30%;
    padding-left: 25px;
}
.events-list-by-date {
    width: 100%;
    float: left;
    clear: left;
    margin-bottom: 30px;
}
.events-list-date-title {
    background: #0d0d0d;
    color: #fff;
    float: left;
    font-size: 13px;
    font-weight: 500;
    padding: 2px 10px;
    position: relative;
    width: 100%;
}
.events-list-item {
    padding: 5px 20px;
    font-size: 13px;
    border-bottom: 1px solid;
    position: relative;
    float: left;
    clear: left;
    background: #fff3e1;
    width: 100%;
}
.events-list-item:hover {
    background: #ffe9c9;
}
.events-list-item::before {
    content: "";
    width: 5px;
    height: 5px;
    background: #f00;
    position: absolute;
    left: 5px;
    bottom: 0;
    margin: auto;
    top: 0;
}
.events-list-item-link {
    text-decoration: none;
    color: black;
    padding-left: 10px;
}
.events-list-item-category-icon {
    height: 20px;
    float: left;
}
.events-list-title {
    text-align: center;
    color: black;
    font-size: 20px;
    font-weight: 300;
    padding: 5px;
    margin: 5px 0 20px;
    width: auto;
    border-bottom: 1px solid gray;
}
.events-block {
    width: 100%;
    position: relative;
    float: left;
    margin: 10px 0;
    padding: 10px;
    height: 100vh;
}
.events-list-item.overused-entry {
    opacity: 0.7;
    background: #d7d7d7;
}
.events-list-item.overused-entry:hover {
    background: #ffe9c9;
    opacity: 1;
}
.events-block-title {
    font-size: 25px;
    font-weight: 700;
    margin: 20px;
    text-align: center;
}
.events-filters-title {
    text-align: center;
    color: white;
    font-size: 15px;
    font-weight: 700;
    background: black;
    padding: 5px;
}
.events-filter-category-title {
    width: 100%;
    clear: both;
    text-align: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    height: 20px;
    display: inline-block;
}
.events-filter-category-image img {
    width: 100%;
}
.events-category-filter-option {
    width: 25%;
    float: left;
    cursor: pointer;
    padding: 0 10px;
    margin: 10px 0;
    height: 95px;
}
.events-filter-category-image.selected-icon {
    background: black;
    border-radius: 50px;
    box-shadow: 0 0 0 #000;
}
.event-today, .event-tomorrow, .event-day, .event-month {
    float: right;
    width: 33%;
}
.event-day {
    text-align: center;
}
.event-month {
    text-align: right;
}
.events-list-item-location {
    float: right;
}
.events-list-item-location-icon {
    width: 15px;
    padding-bottom: 2px;
}

.no-events-message {
    margin: 50px 0;
    font-weight: 700;
    text-align: center;
}

.events-list-item-time {
    float: left;
    padding: 0 0 0 10px;
    font-weight: 500;
}

@media only screen and (max-width: 1200px) {

    .events-category-filter-option {

        width: 33.33%;
    }
    .events-filter-category-title {
        white-space: nowrap;
    }
    .events-filters-section {
        padding-left: 0;
    }
    .events-filter-category-title {
        font-size: 10px;
    }
}

@media only screen and (max-width: 700px) {

    .events-category-filter-option {

        width: 50%;
    }
    .events-filter-category-title {
        font-size: 10px;
    }
    .events-list-section {
        padding-right: 15px;
    }
    .events-list-date-title {
        font-size: 12px;
        font-weight: 700;
    }
    .events-filter-category-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .events-category-filter-option {
        padding: 0;
    }
}