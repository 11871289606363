/* Center the map on the screen */
.map-svg-select-block {
    height: 100vh; /* Full viewport height */
    max-height: 700px;
    padding: 20px;
}
.greece-map {
    width: auto; /* 90% of the viewport width */
    height: auto; /* Maintain aspect ratio */
    max-height: 700px;
    display: block; /* Remove inline padding/margin */
    margin: auto; /* Center the map horizontally */
}

/* Hover effect for paths in the SVG */
.map-svg-select-block svg path {
    cursor: pointer; /* Indicate interactivity */
    transition: fill 0.3s ease; /* Smooth color transition */
}

/* Change color on hover */
.map-svg-select-block svg path:hover {
    fill: #77bd88; /* Highlight color (blue) */
}

/* Optional: Style for clicked paths (active state) */
/*.map-svg-select-block svg path:active {*/
/*    fill: #77bd88; !* Darker blue when clicked *!*/
/*}*/

/* Styling for disabled paths */
.svg-path.disabled-data {
    fill: #c8c8c8; /* Disabled color (gray) */
    /*cursor: not-allowed; !* Change cursor to indicate it's not clickable *!*/
    /*pointer-events: none; !* Disable click events *!*/
}

.svg-path.disabled-data:hover {
    fill: #d5d5d5; /* Disabled color (gray) */
}

/* Tooltip container */
.custom-tooltip {
    background-color: #333; /* Change background color */
    color: #fff; /* Change text color */
    font-size: 14px; /* Adjust font size */
    padding: 8px 12px; /* Add padding */
    border-radius: 4px; /* Add rounded corners */
    border: 1px solid #555; /* Optional border */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
}

/* Tooltip arrow */
.custom-tooltip-arrow {
    color: #333; /* Matches the tooltip background */
}

/* Optional: Add hover transition */
.custom-tooltip {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.select-region-title {
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    text-shadow: 0 1px 3px black;
    position: absolute;
    left: 20%;
    background: #e8e8e8;
    padding: 2px 10px 7px;
    border-radius: 10px;
}
.select-region-back-button {
    background: #fff;
    padding: 5px 10px;
    color: black;
    font-weight: 600;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 10px;
    box-shadow: 0 1px 1px gray;
}

@media (max-width: 768px) {
    .select-region-title{
        bottom:100px;
    }
}
@media (max-width: 576px) {

}
